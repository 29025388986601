import React from "react"
import styled from "styled-components"

import PageWrapper from "../components/PageWrapper"
import BookList from "../components/elements/BookList"

import BabyBuildMyBook from "../images/ebook/Baby_Build_My_Book.jpg"
import FamilyBuildMyBook from "../images/ebook/Family_Build_My_Book.jpg"
import HolidayBuildMyBook from "../images/ebook/Holiday_Build_My_Book.jpg"
import KidsBuildMyBook from "../images/ebook/Kids_Birthday_Build_My_Book.jpg"
import PetsBuildMyBook from "../images/ebook/Pets_Build_My_Book.jpg"
import TravelBuildMyBook from "../images/ebook/Travel_Build_My_Book.jpg"
import WeddingBuildMyBook from "../images/ebook/Wedding_Build_My_Book.jpg"
import WildlifeBuildMyBook from "../images/ebook/Wildlife_Build_My_Book.jpg"

const BOOK_DATA = [
  {
    img: WeddingBuildMyBook,
    title: "Wedding",
    url:
      "https://www.qphoto.co.za/pub/media/wysiwyg/ebooks/wedding_tell_a_story/index.html",
  },
  {
    img: BabyBuildMyBook,
    title: "Baby",
    url:
      "https://www.qphoto.co.za/pub/media/wysiwyg/ebooks/baby_tell_a_story/index.html",
  },
  {
    img: WildlifeBuildMyBook,
    title: "Wildlife",
    url:
      "https://www.qphoto.co.za/pub/media/wysiwyg/ebooks/wildlife_tell_a_story/index.html",
  },
  {
    img: KidsBuildMyBook,
    title: "Kids",
    url:
      "https://www.qphoto.co.za/pub/media/wysiwyg/ebooks/kids_birthday_tell_a_story/index.html",
  },
  {
    img: FamilyBuildMyBook,
    title: "Family",
    url:
      "https://www.qphoto.co.za/pub/media/wysiwyg/ebooks/family_tell_a_story/index.html",
  },
  {
    img: HolidayBuildMyBook,
    title: "Holiday",
    url:
      "https://www.qphoto.co.za/pub/media/wysiwyg/ebooks/holiday_tell_a_story/index.html",
  },
  {
    img: PetsBuildMyBook,
    title: "Pets",
    url:
      "https://www.qphoto.co.za/pub/media/wysiwyg/ebooks/pet_tell_a_story/index.html",
  },
  {
    img: TravelBuildMyBook,
    title: "Travel",
    url:
      "https://www.qphoto.co.za/pub/media/wysiwyg/ebooks/travel_tell_a_story/index.html",
  },
]

const ExamplesPage = () => (
  <PageWrapper>
    <Header>
      <h1>Build My Book Example Photo Books</h1>
    </Header>
    <BookListContainer>
      <BookList data={BOOK_DATA} />
    </BookListContainer>
  </PageWrapper>
)

const Header = styled.div`
  margin: 50px 0;
  h1 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    color: ${props => props.theme.color.grey};
    text-align: center;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
  }
`

const BookListContainer = styled.div`
  padding-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 10px;
  @media only screen and (min-width: 768px) {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 10px;
  }
  @media only screen and (min-width: 992px) {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 10px;
  }
  @media only screen and (min-width: 1200px) {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 10px;
  }
  p {
    font-family: ${props => props.theme.font.galanoMed};
  }
  h3 {
    text-align: center;
    font-family: ${props => props.theme.font.galanoSemi};
  }
`
export default ExamplesPage
