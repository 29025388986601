import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const BookList = ({ data }) => {
  return (
    <>
      {data.map((book, i) => {
        return (
          <Container key={i}>
            <a
              href={book.url}
              title={book.title}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>{book.title}</h3>
              <img src={book.img} alt={book.title} />
              <p>Click Here To Preview</p>
            </a>
          </Container>
        )
      })}
    </>
  )
}

const Container = styled.div`
  max-witdh: 100%;
  text-align: center;
  padding: 10px;
  img {
    max-width: 100%;
  }
  -webkit-box-shadow: 0px 0px 5px 0px rgba(209, 209, 209, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(209, 209, 209, 1);
  box-shadow: 0px 0px 5px 0px rgba(209, 209, 209, 1);
`

BookList.propTypes = {
  data: PropTypes.array,
}

export default BookList
